<template>
	<progress max="100" :value.prop="uploadPercentage" v-if="uploadPercentage"></progress>
	<div class="tools">

		<o-upload v-model="file_m" accept="image/*, video/*" :multiple=true>
			<o-button tag="a" variant="primary" size="small">
				<o-icon icon="upload" />
				<span>Пакетная загрузка</span>
			</o-button>
			<o-loading v-model:active="isLoading" :full-page="false" :can-cancel="false">
				<o-icon pack="fas" icon="sync-alt" size="medium" spin />
			</o-loading>
		</o-upload>
		<template v-if="file_m" class="file-name">
			{{ upload(file_m, null) }}
		</template>

		<o-input v-model="this.$root.user.sub_id_2" size="small" variant="primary" style="width:6em; margin-left:1em; margin-right:auto;" placeholder="sub_id_2" /><!-- v-if="this.$root.user.sub_id_2" -->
		<o-input v-model="this.tag" size="small" variant="primary" style="width:6em; margin-left:1em; margin-right:auto;" placeholder="Тэг" />

		<div class="filters">
			<o-field variant="primary" style="margin-right:1em; margin-bottom:0;">
				<o-select size="small" v-model="filter" @change="this.$root.loadData({'filter':filter}, this, (dt) => { this.data = dt })">
					<option value="conv">Конверсий > 0</option>
					<option value="clicks">Кликов > 10</option>
					<option value="">Все</option>
				</o-select>
			</o-field>
			<Interval @change="$event.filter=filter, this.$root.loadData($event, this, (dt) => { this.data = dt })" />
		</div>

	</div>

	<o-notification :message="uploadMsg" v-if="uploadMsg" @close="this.uploadMsg=''" closable></o-notification>

 	<o-table :data="data" ref="table" :bordered=true :striped=true :narrowed=true :hoverable=true :detailed=true :show-detail-icon=false :sort-icon="'arrow-up'" :paginated=true :per-page="perpage" :pagination-simple=true variant="primary"> <!-- v-model:selected="selected" @update:selected="console.log(props)" -->
		<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column">

			<template v-if="column.searchable && !column.numeric" #searchable="props">
				<o-input v-model="props.filters[props.column.field]" placeholder="Search" icon="search" size="small" />
			</template>

			<template #default="props">
				<div @click="toggle(props)">
					<template v-if="column.field=='type' && props.row[column.field]">
						<o-icon pack="fas" v-model:icon="props.row[column.field]" size="small" />
					</template>
					<template v-else>
						{{ props.row[column.field] + this.$root.units(column.field) }}
					</template>
				</div>
			</template>

		</o-table-column>

		<template #detail="props">

			<template v-if="props.row.type=='video'">
				<video preload="metadata" controls class="creo"><source :src="props.row.creo"></video>
			</template>
			<template v-else>
				<img :src="props.row.creo" class="creo" />
			</template>
			<!-- template v-if="props.row.date==''" -->
			<o-field class="file">
				<o-upload v-model="file" accept="image/*, video/*">
					<o-button tag="a" variant="primary" size="small" >
						<o-icon icon="upload" />
						<span v-if="props.row.date!=''">Заменить</span>
						<span v-if="props.row.date==''">Загрузить</span>
					</o-button>
				</o-upload>
				<template v-if="file" class="file-name">
					{{ upload(file, props.row) }}
				</template>
				<o-loading v-model:active="isLoading" :full-page="false" :can-cancel="false">
					<o-icon pack="fas" icon="sync-alt" size="medium" spin />
				</o-loading>
			</o-field>

			<div class="offers">
				<o-table :data="data_offers[props.index]" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'">
					<o-table-column v-for="(column, idx) in columns_offers" :key="idx" v-bind="column" v-slot="{ row }">
						<template v-if="column.field=='offer'">
							<a :href="'/admin/?object=offers.preview&id=' + row.offer_id" target="_blank">{{ row[column.field]  }}</a>
						</template>
						<template v-else>
							{{ row[column.field] + this.$root.units(column.field) }}
						</template>
					</o-table-column>
				</o-table>
			</div>

		</template>

		<template v-slot:bottom-left>
			<o-field variant="primary">
				<o-select size="small" v-model="perpage">
				<option v-for="item in [25,50,100,200,500]" :value="item">
					{{ item }}
				</option>
				</o-select>
			</o-field>
		</template>

	</o-table>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'Creos',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
					{ field:'sub_id_3', label:'Крео', sortable:true, searchable:true },
					{ field:'sub_id_2', label:'Автор', sortable:true, searchable:true },
					{ field:'tag', label:'Тэг', sortable:true, searchable:true, filters:'ddd' },
					{ field:'type', label:'Тип', sortable:true },
					{ field:'date', label:'Дата', sortable:true },
					{ field:'clicks', label:'Клики', sortable:true, numeric:true },
					{ field:'conversions', label:'Конв.', sortable:true, numeric:true },
					{ field:'cpa', label:'CPA', sortable:true, numeric:true, position:'right' },
					{ field:'cr', label:'CR', sortable:true, numeric:true, position:'right' },
					{ field:'country_code', label:'Страна', sortable:true, searchable:true },
				],
				aaa: 'ddd',
				data: [],
				tag: '',
				filter: 'conv',
				perpage: 25,
				file: null,
				file_m: null,
				isLoading: false,
				columns_offers: [
					{ field:'offer', label:'Оффер', sortable:true },
					{ field:'clicks', label:'Клики', sortable:true },
					{ field:'conversions', label:'Конв.', sortable:true },
					{ field:'approve', label:'Апрув', sortable:true, position:'right' },
					{ field:'cost', label:'Расход', sortable:true, position:'right' },
					{ field:'profit_confirmed', label:'Прибыль', sortable:true, position:'right' },
					{ field:'roi_confirmed', label:'ROI', sortable:true, position:'right' },
					{ field:'sales', label:'Продажи', sortable:true },
					{ field:'cpa', label:'CPA', sortable:true, position:'right' },
					{ field:'cr', label:'CR', sortable:true, position:'right' },
				],
				data_offers: [],
				uploadPercentage: 0,
				uploadMsg: '',
			}
		},
		computed: {
//			data() { return this.$root.data }
		},
		methods: {
			toggle(props) {
				this.$root.loadData({ sub_id_2:props.row.sub_id_2, sub_id_3:props.row.sub_id_3 }, this, (dt) => { this.data_offers[props.index] = dt })
				props.toggleDetails(props.row)
			},
			upload(file, row) {
				this.isLoading = true
				let formData = new FormData()
				formData.append('action', this.$.type.name)
				formData.append('tag', this.tag)
				if(row) {
					formData.append('sub_id_3', row.sub_id_3)
					formData.append('sub_id_2', row.sub_id_2)
					formData.append('file', file)
					this.file = null
				} else {
					formData.append('multiple', '1')
					if(this.$root.user.sub_id_2) formData.append('sub_id_2', this.$root.user.sub_id_2)
					Object.keys(file).forEach((i) => formData.append('file'+i, file[i]));
					this.file_m = null
				}
				this.axios.post(this.$root.api, formData, {headers:{'Content-Type':'application/x-www-form-urlencoded'}, onUploadProgress: function(progressEvent) { this.uploadPercentage = parseInt(Math.round((progressEvent.loaded/progressEvent.total)*100))}.bind(this) }).then((response) => {
					this.uploadPercentage = 0
					this.isLoading = false
					console.log(response)
					if(response.data.creo) {
						row.date = response.data.date
						row.type = response.data.type
						row.creo = response.data.creo
						row.tag = response.data.tag
					} else {
						this.uploadMsg = '<p>Загружено:</p>'+response.data.msg
						this.$root.loadData({'filter':this.filter}, this, (dt) => { this.data = dt })
					}
				}).catch((error) => { console.log(error) });
			},
		},
		mounted() {
			let tag = this.$route.query.tag
			if(typeof tag == 'undefined') { tag = '' }
			this.$root.loadData({'filter':this.filter, 'tag':tag}, this, (dt) => {
				this.data = dt
				if(tag) {
					this.$refs.table.filters['tag'] = tag
					this.tag = tag
				}
			})
		},
	}
</script>

<style>
.detail .field, .detail .creo{ float:left; width:20%; margin-bottom:6px; }
.detail .file{ float:left; clear:both; }
.offers{ font-size:0.9em; width:79%; margin-left:21%; }
td[data-label="Оффер"], td[data-label="Крео"]{ width:200px!important; white-space:normal; }
progress{ accent-color:#00d1b2; position:fixed; bottom:0px; left:0px; width:100%; height:10px; z-index:1000; }
.level{ justify-content:flex-end; }
.level>div{ margin-right:1em; }
.pagination-link.is-current { background-color:#00d1b2; border-color:#00d1b2; }
</style>
