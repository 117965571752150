<template>
	<div class="tools">
		<div class="filters">
			<o-checkbox v-model="this.south" label="South" size="small" @update:modelValue="this.loadData(null)" />
			<Interval ref="interval" @change="this.loadData($event)" />
		</div>
	</div>

 	<o-table ref="table" :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :detailed=true :show-detail-icon=false :sort-icon="'arrow-up'" @filters-change="this.countTotal(this.$refs.table.newData)" :debounce-search="1000">
		<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column">

			<template v-if="column.searchable && !column.numeric" #searchable="props">
				<o-input v-model="props.filters[props.column.field]" placeholder="Search" icon="search" size="small" />
			</template>

			<template v-if="column.total" #subheading="props">
				{{ total[column.field] + this.$root.units(column.field) }}
			</template>

			<template #default="props">
				{{ props.row[column.field] + this.$root.units(column.field) }}
			</template>

		</o-table-column>

	</o-table>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'Tracking',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
					{ field:'source', label:'Байер', sortable:true, searchable:true },
					{ field:'kt', label:'КТ', sortable:true, searchable:true },
					{ field:'sale_revenue', label:'Доход', sortable:true, position:'centered', total:true },
					{ field:'profit_confirmed', label:'Прибыль', sortable:true, position:'centered', total:true },
					{ field:'roi_confirmed', label:'ROI', sortable:true, position:'centered', total:true, },
					{ field:'cr', label:'CR', sortable:true, position:'centered', total:true },
					{ field:'approve', label:'Апрув', sortable:true, position:'centered', total:true },
					{ field:'conversions', label:'Конв.', sortable:true, position:'centered', total:true },
					{ field:'cpa', label:'CPA', sortable:true, position:'centered', total:true },
					{ field:'epc_confirmed', label:'EPC', position:'centered', total:true, sortable:true, },
					{ field:'clicks', label:'Клики', sortable:true, position:'centered', total:true },
					{ field:'cost', label:'Расход', sortable:true, position:'centered', total:true },
					{ field:'sales', label:'Продажи', sortable:true, position:'centered', total:true },
				],
				data: [],
				params: {},
				total: { sale_revenue:0, profit_confirmed:0, cost:0, approve:0, conversions:0, clicks:0, sales:0, cr:0, cpa:0, roi_confirmed:0, epc_confirmed:0 },
				south: true,
			}
		},
		methods: {
			countTotal(dt) {
				this.total.sale_revenue = 0, this.total.profit_confirmed = 0, this.total.cost = 0, this.total.approve = 0, this.total.conversions = 0, this.total.clicks = 0, this.total.sales = 0, this.total.cr = 0, this.total.cpa = 0, this.total.roi_confirmed = 0, this.total.epc_confirmed = 0
				dt.forEach((row) => {
					this.total.sale_revenue += Math.round(row.sale_revenue)
					this.total.profit_confirmed += Math.round(row.profit_confirmed)
					this.total.cost += Math.round(row.cost)
					this.total.conversions += Math.round(row.conversions)
					this.total.clicks += Math.round(row.clicks)
					this.total.sales += Math.round(row.sales)
				})
				if(this.total.conversions) this.total.approve = Math.round(this.total.sales / this.total.conversions * 100)
				if(this.total.clicks) this.total.cr = this.total.conversions / this.total.clicks * 100
				this.total.cr = this.total.cr.toFixed(2)
				if(this.total.conversions) this.total.cpa = this.total.cost / this.total.conversions
				this.total.cpa = this.total.cpa.toFixed(2)
				if(this.total.cost) this.total.roi_confirmed = this.total.profit_confirmed / this.total.cost * 100
				this.total.roi_confirmed = this.total.roi_confirmed.toFixed(2)

				if(this.total.clicks) this.total.epc_confirmed = this.total.sale_revenue / this.total.clicks
				this.total.epc_confirmed = this.total.epc_confirmed.toFixed(2)
			},
			loadData(e) {
				if(e && e.interval) this.params.interval = e.interval
				if(e && e.interval == 'custom_date_range') {
					this.params.from = e.from
					this.params.to = e.to
				}
				this.params.south = this.south
				this.$root.loadData(this.params, this, (dt) => {
					this.data = dt
					this.$refs.table.filters['source'] = '', this.$refs.table.filters['kt'] = ''
					this.countTotal(dt)
				})
			}
		},
		mounted() {
			this.loadData(null)
		},
	}
</script>

<style>
</style>
