<template>
	<div>
		<o-field label="Лидерборд" variant="primary">
			<Interval ref="interval" @change="this.$root.loadData($event, this, (dt) => { this.columns_lt[1].label='Топ команд '+this.$refs.interval.intervals[$event.interval]; this.data=dt.leaders; this.data_lt=dt.lt })" />
		</o-field>

		<o-table :data="data_lt" :bordered=true :striped=true :narrowed=true :hoverable=true :mobile-cards=false>
			<o-table-column v-for="(column, idx) in columns_lt" :key="idx" v-bind="column" v-slot="{ row }">
				{{ row[column.field] }}
			</o-table-column>
		</o-table>
		<br />
		<o-table :data="data" ref="leaders" :bordered=true :striped=true :narrowed=true :hoverable=true :mobile-cards=false>
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column">
				<template #default="props">
					<span v-bind:title="column.field=='name'?props.row['source']:''" v-bind:class="'n'+props.row['num'] + (props.row['source']==this.$root.user.sub_id_2 ? ' my':'') + (column.field=='profit_confirmed' && props.row['hide'] ? ' hidden':'')">
						{{ props.row[column.field] + this.$root.units(column.field) }}
					</span>
				</template>
			</o-table-column>
		</o-table>

	</div>
	<div class="contest">

		<o-field label="Contest с 26 декабря" variant="primary">&nbsp;</o-field>

		<o-table :data="data_teams" :bordered=true :striped=true :narrowed=true :hoverable=true :mobile-cards=false>
			<o-table-column v-for="(column, idx) in columns_teams" :key="idx" v-bind="column" v-slot="{ row }">
			{{ row[column.field] + (row[column.field]?this.$root.units(column.field):'') }}
			</o-table-column>
		</o-table>
		<br />
		<o-table :data="data_users" :bordered=true :striped=true :narrowed=true :hoverable=true :mobile-cards=false>
			<o-table-column v-for="(column, idx) in columns_users" :key="idx" v-bind="column" v-slot="{ row }">
				{{ row[column.field] + (row[column.field]?this.$root.units(column.field):'') }}
			</o-table-column>
		</o-table>


	</div>
</template>

<script>
	import Interval from './Interval.vue'
	export default {
		name: 'Leaders',
		components: {
			Interval
		},
		data() {
			return {
				columns: [
					{ field:'num', label:'' },
					{ field:'name', label:'Байер' },
					{ field:'profit_confirmed', label:'Прибыль', position:'right' },
					{ field:'roi_confirmed', label:'ROI', position:'right' },
				],
				data: [],
				columns_lt: [
					{ field:'num', label:'' },
					{ field:'team', label:'Топ команд Сегодня' },
				],
				data_lt: [],
				columns_teams: [
					{ field:'num', label:'' },
					{ field:'team', label:'Топ команд Contest' },
					{ field:'profit_confirmed', label:'Прибыль', position:'right' },
				],
				data_teams: [],
				columns_users: [
					{ field:'num', label:'' },
					{ field:'name', label:'Топ байеров' },
					{ field:'profit_confirmed', label:'Прибыль', position:'right' },
				],
				data_users: [],
			}
		},
		methods: {
		},
		computed: {
			//data() { return this.$root.data }
		},
		mounted() {
			this.$root.loadData(null, this, (dt) => {
				this.data = dt.leaders
				this.data_lt = dt.lt
				this.data_teams = dt.teams
				this.data_users = dt.users
			})
		},
	}
</script>

<style>
.leaders{ margin-left:0; display:flex; }
.leaders>div{ display:block; width:49%; margin-right:1%;}
.onmobile	.leaders{ display:block; }
.onmobile	.leaders>div{ width:100%; margin-right:0%;}

.leaders .hidden{ filter:blur(0.3rem); }
.leaders .my{ font-weight:bold; }

.leaders td:first-child{ color:gray; width:2em;}
.leaders tr:first-child{ color:red; }
.leaders tr:nth-of-type(2){ color:blue; }
.leaders tr:nth-of-type(3){ color:green; }
.contest td:last-child{ width:5em; }
</style>
