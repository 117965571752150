import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import Axios from 'axios'
import Oruga from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'
import '@oruga-ui/theme-bulma/dist/bulma.css'

import SvgIcon from '@jamescoyle/vue-icon';
import { mdiAccount } from '@mdi/js'

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import JsonExcel from "vue-json-excel3"

import Dashboard from './components/Dashboard.vue'
import Tracking from './components/Tracking.vue'
import Creos from './components/Creos.vue'
import Offers from './components/Offers.vue'
import Finances from './components/Finances.vue'
import Affp from './components/Affp.vue'
import Users from './components/Users.vue'
import Html from './components/Html.vue'
import Analytics from './components/Analytics.vue'
import Leads from './components/Leads.vue'
import LeadsStat from './components/LeadsStat.vue'
import Resends from './components/Resends.vue'
import SendOffers from './components/SendOffers.vue'
import SendAffs from './components/SendAffs.vue'
import SendBranches from './components/SendBranches.vue'
import TaskTracking from './components/TaskTracking.vue'


const router = createRouter({
	routes: [
		{ path:'/', component:Dashboard, name:'Дашбоард', notadmin:true, },
		{ path:'/tracking', component:Tracking, name:'Tracking'},
		{ path:'/task_tracking', component:TaskTracking, name:'Task Tracking'},
		{ path:'/creos', component:Creos, name:'Креативы'},
		{ path:'/offers', component:Offers, name:'Офферы'},
		{ path:'/finances', component:Finances, name:'Финансы'},
		{ path:'/affp', component:Affp, name:'ПП'},
		{ path:'/users', component:Users, name:'Пользователи'},
		{ path:'/tools', component:Dashboard},
		{ path:'/prize', component:Html, name:'Призы' },
		{ path:'/analytics', component:Analytics, name:'Время просмотра' },
		{ path:'/leads', component:Leads, name:'Выгрузка лидов' },
		{ path:'/leadsstat', component:LeadsStat, name:'Статистика лидов' },
		{ path:'/resend', component:Resends, name:'Переотправка' },
		{ path:'/sendoffers', component:SendOffers, name:'Офферы для отправки' },
		{ path:'/sendaffs', component:SendAffs, name:'Аккаунты для отправки' },
		{ path:'/sendbranches', component:SendBranches, name:'Логины ПП' },
		{ path:'/:pathMatch(.*)', component:Dashboard, },
	],
	history: createWebHashHistory(),
})


const app = createApp(App)
app.use(router)
app.use(Oruga, bulmaConfig)
app.use(VueSidebarMenu)
app.component("downloadExcel", JsonExcel)
//Axios.defaults.withCredentials = true
app.config.globalProperties.axios = Axios
app.mount('#app')
