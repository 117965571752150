<template>
	<div class="dashboard">
		<div class="leaders"><Leaders /></div>
		<div class="news"><News /></div>
	</div>
</template>

<script>
	import Leaders from './Leaders.vue'
	import News from './News.vue'
	export default {
		components: {
			Leaders, News
		},
		data: null,
	}
</script>

<style>
.dashboard{ display:flex; }
.leaders{ width:60%; }
.news{ width:40%; }
.dashboard .button, .leaders select, .leaders input { margin-left:1em; height:24px; }
.leaders select, .leaders input{ padding-top:0; padding-bottom:0; }


.onmobile .dashboard, .onmobile .news{ flex-direction:column; }
.onmobile	.leaders, .onmobile .news{ width:100%; margin:5px 0;}

</style>
