<template>
	<div class="header">
		<h1 class="title is-5">{{ this.$route.name }}</h1>
		<div style="display:flex; flex-direction:column;">
			<p style="font-size:1.5em; line-height:0">KPI</p>
			<p style="font-size:0.8em; line-height:0"><br>сегодня</p>
		</div>
		<div>
			<p>Прибыль<br>{{ this.data.day_profit }} $</p>
		</div>
		<div>
			<p>Расход<br>{{ this.data.day_cost }} $</p>
		</div>
		<div>
			<p>ROI<br>{{ this.data.roi_confirmed }}%</p>
		</div>
		<div class="user">
			<p>{{ this.$root.user.name }}</p>
			<img :src="this.$root.user.ava" />
		</div>
	</div>
	<div id="goal" :style="{'background':'linear-gradient(90deg, rgba(0,209,178,0.3) 0%, rgba(0,209,178,0.3) '+this.percent+'%, rgba(255,255,255,1) '+(this.percent+4)+'%, rgba(255,255,255,1) 100%)'}">
		{{ 'Цель месяца '+' '+this.data.goal_text+': '+this.data.month_profit+' / '+this.data.month_goal+' $' }}
	</div>
</template>

<script>
export default {
	name: 'Header',
	data() {
		return {
			interval: 'today',
			data: {},
			percent: 0,
		}
	},
	methods: {
	},
	mounted() {
		this.data.goal_text = ''
		this.data.month_profit = '0'
		this.data.month_goal = '1'
		this.$root.loadData(this.$root.admin ? null : {sub_id_2:this.$root.user.sub_id_2}, this, (dt) => {
			this.data = dt
			this.percent = dt.month_profit / dt.month_goal * 100
		})
	},
}

</script>

<style>
	.header{ height:50px; display:flex; justify-content:flex-end; margin-bottom:10px; }
	.header div{ display:flex; margin-left:20px; }
	.header div.user{ margin-left:60px; }
	.onmobile .header div.user{ margin-left:10px; }
	.header p{ margin:auto; }
	.header h1{ margin:auto auto auto 0; }
	.user img{ height:100%; width:auto; margin:0 0 0 20px; border-radius:50%; border:1px solid gray; padding:2px;}
	#goal{ width:100%; border-radius:2px; padding:0 1em; font-size:0.7em; margin-bottom:7px; color:black; }
</style>
