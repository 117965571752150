<template>
	<div class="tools">
		<div class="filters" style="width:100%;">
			<div style="margin-left:0 auto 0 0;">
				<Sub_id_2 @change="change($event)" />
			</div>
			<div style="margin:0 0 0 auto;">
				<Interval type="month" @change="change($event)" />
			</div>
		</div>
	</div>

	<div class="finances">
		<o-table :data="data" :bordered=true :striped=true :narrowed=true :hoverable=true :sort-icon="'arrow-up'">
			<o-table-column v-for="(column, idx) in columns" :key="idx" v-bind="column" v-slot="{ row }">
				<template v-if="row[column.field]">
					{{ row[column.field] + this.$root.units(column.field) }}
				</template>
			</o-table-column>
		</o-table>
	</div>

</template>

<script>
	import Interval from './Interval.vue'
	import Sub_id_2 from './Sub_id_2.vue'
	export default {
		name: 'Finances',
		components: {
			Interval, Sub_id_2
		},
		data() {
			return {
				columns: [
					{ field:'day', label:'Дата', sortable:true },
					{ field:'bro', label:'Spend BRO', sortable:true, position:'right' },
					{ field:'cost', label:'Spend KT', sortable:true, position:'right' },
					{ field:'sale_revenue', label:'Revenue', sortable:true, position:'right' },
					{ field:'conversions', label:'Лиды', sortable:true },
					{ field:'sales', label:'Аппрувы', sortable:true },
					{ field:'approve', label:'% Апрува', sortable:true },
					{ field:'profit_confirmed', label:'Прибыль', sortable:true, position:'right' },
					{ field:'roi_confirmed', label:'ROI', sortable:true, position:'right' },
				],
				data: [],
				params: {'sub_id_2':this.$root.admin ? '' : this.$root.user.sub_id_2},
			}
		},
		methods: {
			change(event) {
				this.data = []
				this.data[0] = {'day':'Loading...'}
				this.params = Object.assign(this.params, event)
				this.$root.loadData(this.params, this, (dt) => { this.data = dt })
			},
		},
		mounted() {
			//this.$root.loadData(null, this, (dt) => { this.data = dt })
		},
	}
</script>

<style>
.finances tr:last-child {font-weight:bold; }
</style>
